import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import avighnaSteel from "../../../assets/images/avighanaLogo.svg";
import footeravighanaLogo from "../../../assets/images/footeravighanaLogo.svg"
import callLogo from "../../../assets/images/callLogo.svg";
import { NewsLetterTemplate } from '../../templates/Home/NewsLetter.template';
import { CUSTOMER_ROUTES, HOME_PAGE_ROUTES, NUMBER_URL } from '../../../utils/constant';
import { Link, useLocation } from 'react-router-dom'
import X_LOGO from '../../../assets/images/X-Logo.svg'
import facebookLogo from "../../../assets/icons/Facebook.svg"
import instaLogo from "../../../assets/icons/instagram.svg"
import linkedInLogo from "../../../assets/icons/Linkedin.svg"

const useStyles = createUseStyles((theme: any) => ({
    footer: {
        maxWidth: "1300px"
    },
    borderTop: {
        borderTop: `1px solid ${theme.palette.borderV2.secondary.secondary200}`,
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.borderV2.secondary.secondary200}`,
    },
    icon: {
        border: `1px solid ${theme.palette.borderV2.secondary.secondary200}`,
    },
    border: {
        borderBottom: `1px solid ${theme.palette.borderV2.secondary.secondary200}`,
    },
    copyright: {
        color: theme.palette.textV2.secondary.secondary800
    },
    text: {
        color: theme.palette.textV2.secondary.secondary700
    },
    address: {
        color: theme.palette.textV2.secondary.secondary800
    },
    whatsapp: {
        borderRadius: "50px",
        background: `var(--AvighnaGradient-100, linear-gradient(180deg, #EBEFFA 0%, #D6DEF5 100%))`
    },
    whatsappText: {
        color: theme.palette.textV2.secondary.secondary500
    },
    footerText: {
        color: theme.palette.textV2.tertiary.tertiary900
    },
    mobilefooter: {
        display: 'none',
    },
    footerLogo: {},
    "@media (max-width: 480px)": {
        footer: {
            display: 'none',
        },
        mobilefooter: {
            display: 'grid',
        },
        footerLogo: {
            width: '74px',
        },
    }
}));

const Footer: React.FC = () => {

    const classes = useStyles();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [showInitialButton, setShowInitialButton] = useState(true);
    const location = useLocation();
    useEffect(() => {
        const handleScroll = () => {
            toggleVisibility();
        };
        document.addEventListener('wheel', handleScroll);
        return () => {
            document.removeEventListener('wheel', handleScroll);
        };
    }, []);
    const toggleVisibility = () => {
        const scrolled = document.getElementById('main')?.scrollTop;
        if (scrolled && scrolled > 200) {
            setIsVisible(true)
        }
        else {
            setIsVisible(false)
        }
    };
    const scrollToTop = () => {
        document.getElementById('main')?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        setIsVisible(false);
    };

    return (
        <>
            {/* <div className='fixed bottom-14 right-10 gap-4 m-4'>
                { !location.pathname.includes("/dashboard") && <div className='flex items-center'>
                    {showInitialButton ? (
                        <button
                            className="mr-3"
                            onMouseEnter={() => setShowInitialButton(false)}
                        >
                            <img src={whatsAppIcon} alt="whatsapp" />
                        </button>
                    ) : (
                        <a
                            href={NUMBER_URL.WHATSAPP}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button
                                onMouseLeave={() => setShowInitialButton(true)}
                            >
                                <div className={`${classes.whatsapp} mr-3`}>
                                    <div className='flex item-center p-4 gap-2 text-center'>
                                        <img src={whatsappLogo} alt="whatsappLogo" />
                                        <div className={`${classes.whatsappText} text-base mt-1 font-medium`}>Get Catalog on WhatsApp</div>
                                    </div>
                                </div>
                            </button>
                        </a>
                    )}
                    {isVisible && (
                        <button
                            onClick={scrollToTop}
                        >
                            <img src={scrollIcon} alt="scroll" />
                        </button>
                    )}
                </div>}
            </div> */}

            <footer className={`${classes.footer} mx-auto mt-24`}>
                <div className={`flex justify-between pt-14 pb-8 ${classes.borderTop} ${classes.borderBottom}`}>
                    <div className='flex gap-x-8 justify-between'>
                        <div className='flex flex-col'>
                            <span className='mb-3 font-semibold text-lg'>Our Products</span>
                            <a href={HOME_PAGE_ROUTES.COLDROLLED_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Cold Rolled</a>
                            <a href={HOME_PAGE_ROUTES.GALVANIZED_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Galvanized</a>
                            <a href={HOME_PAGE_ROUTES.ELECTROLYTIC} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Electrolytic Tinplate</a>
                            <a href={HOME_PAGE_ROUTES.TINFREE_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Tinfree steel</a>
                            <a href={HOME_PAGE_ROUTES.TINMILL_BLACK_PLATE} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Tinmill Black plate</a>
                            <a href={HOME_PAGE_ROUTES.COLOR_COATED} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Color Coated</a>
                        </div>
                        <div className='flex flex-col'>
                            <span className='mb-3 font-semibold text-lg'>Our Services</span>
                            <a href={HOME_PAGE_ROUTES.SLITTING} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Slitting</a>
                            <a href={HOME_PAGE_ROUTES.SHEARING} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Shearing</a>
                            <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Blanking</a>
                            <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Metal Printing</a>
                            <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Warehousing Services</a>
                            <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Steel Logistics</a>
                        </div>
                        <div className='flex flex-col'>
                            <span className='mb-3 font-semibold text-lg'>About Us</span>
                            <a href={HOME_PAGE_ROUTES.ABOUT_US} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>About Us</a>
                            <a href={HOME_PAGE_ROUTES.VISSION_MISSION} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Vision and Mission</a>
                            <a href={HOME_PAGE_ROUTES.AVIGHNA_ADVANTAGE} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>SME Advantage</a>
                        </div>
                    </div>
                    <div className='max-w-[299px] flex flex-col'>
                        <span className='font-semibold text-lg mb-5'>Contact us</span>
                        <div className={`${classes.address} flex flex-col items-start gap-4 font-normal text-sm`} >
                            <div>
                                <div className={`${classes.footerText} font-medium mb-1 text-sm`}>  Delhi Warehouse </div>
                                Plot no . 56/18/1, Ground Floor, Swarn Park, Mundka, Delhi-110041
                            </div>
                            <div>
                                <div className={`${classes.footerText} font-medium mb-1 text-sm`}> Mumbai Warehouse </div>
                                J-18, MIDC Taloja Industrial Area, Dist. Raigad, Maharashtra -410208
                            </div>
                            <div>
                                A-88, MIDC Taloja Industrial Area, Dist. Raigad, Maharashtra -410208
                            </div>
                            <div className='flex gap-2 w-full'>
                                <img src={callLogo} alt="Call Logo" /> +91 9654555510
                            </div>
                        </div>
                    </div>
                    <div className={`grid gap-y-5 h-fit`}>
                        <NewsLetterTemplate />
                        <div className='flex gap-x-3'>
                            <Link to="https://www.instagram.com/avighna_steel/" target="_blank" rel="noopener noreferrer">
                                <img src={instaLogo} alt="instagram" className={`${classes.icon} rounded-full cursor-pointer`} />
                            </Link>
                            <Link to="https://www.linkedin.com/company/avighna-steel-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                                <img src={linkedInLogo} alt="linkedIn" className={`${classes.icon} rounded-full cursor-pointer`} />
                            </Link>
                            <Link to="https://www.facebook.com/profile.php?id=61550522717455" target="_blank" rel="noopener noreferrer">
                                <img src={facebookLogo} alt="facebook" className={`${classes.icon} rounded-full cursor-pointer`} />
                            </Link>
                            <Link to="https://twitter.com/Avighna_steel" target="_blank" rel="noopener noreferrer">
                                <img src={X_LOGO} alt="X" className={`${classes.icon} rounded-full  cursor-pointer`} />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={`flex pb-28 pt-8 justify-between`}>
                    <img src={avighnaSteel} className='' alt="Avighna Logo" />
                    <div className={`${classes.copyright} my-auto text-sm`}>
                        Copyright © {new Date().getFullYear()} Avighna Steel
                    </div>
                    <div className='flex gap-x-4 my-auto text-sm font-medium'>
                        <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.text} cursor-pointer`}>Terms & Conditions</Link>
                        <Link to={CUSTOMER_ROUTES.PRIVACY_POLICY} className={`${classes.text} cursor-pointer`}>Privacy Policy</Link>
                    </div>
                </div>
            </footer>


            <footer className={`${classes.mobilefooter} gap-y-5 mt-8 mx-4 pt-5 ${classes.borderTop} `}>
                <div className={`grid gap-y-5`}>
                    <NewsLetterTemplate />
                    <div className='flex gap-x-3'>
                        <Link to="https://www.instagram.com/avighna_steel/" target="_blank" rel="noopener noreferrer">
                            <img src={instaLogo} alt="instagram" className={`${classes.icon} rounded-full cursor-pointer`} />
                        </Link>
                        <Link to="https://www.linkedin.com/company/avighna-steel-pvt-ltd/" target="_blank" rel="noopener noreferrer">
                            <img src={linkedInLogo} alt="linkedIn" className={`${classes.icon} rounded-full cursor-pointer`} />
                        </Link>
                        <Link to="https://www.facebook.com/profile.php?id=61550522717455" target="_blank" rel="noopener noreferrer">
                            <img src={facebookLogo} alt="facebook" className={`${classes.icon} rounded-full cursor-pointer`} />
                        </Link>
                        <Link to="https://twitter.com/Avighna_steel" target="_blank" rel="noopener noreferrer">
                            <img src={X_LOGO} alt="X" className={`${classes.icon} rounded-full  cursor-pointer`} />
                        </Link>
                    </div>
                </div>
                <div className={`grid gap-y-5`}>
                    <div className='grid gap-y-4'>
                        <div className='flex gap-x-14'>
                            <div className='flex flex-col'>
                                <span className='mb-3 font-semibold text-lg'>Our Services</span>
                                <a href={HOME_PAGE_ROUTES.SLITTING} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Slitting</a>
                                <a href={HOME_PAGE_ROUTES.SHEARING} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Shearing</a>
                                <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Blanking</a>
                                <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Metal Printing</a>
                                <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Warehousing Services</a>
                                <a href='' className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Steel Logistics</a>
                            </div>
                            <div className='flex flex-col'>
                                <span className='mb-3 font-semibold text-lg'>About Us</span>
                                <a href={HOME_PAGE_ROUTES.ABOUT_US} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>About Us</a>
                                <a href={HOME_PAGE_ROUTES.VISSION_MISSION} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Vision and Mission</a>
                                <a href={HOME_PAGE_ROUTES.AVIGHNA_ADVANTAGE} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>SME Advantage</a>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <span className='mb-3 font-semibold text-lg'>Our Products</span>
                            <a href={HOME_PAGE_ROUTES.COLDROLLED_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Cold Rolled</a>
                            <a href={HOME_PAGE_ROUTES.GALVANIZED_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Galvanized</a>
                            <a href={HOME_PAGE_ROUTES.ELECTROLYTIC} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Electrolytic Tinplate</a>
                            <a href={HOME_PAGE_ROUTES.TINFREE_STEEL} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Tinfree steel</a>
                            <a href={HOME_PAGE_ROUTES.TINMILL_BLACK_PLATE} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Tinmill Black plate</a>
                            <a href={HOME_PAGE_ROUTES.COLOR_COATED} className={`${classes.text} cursor-pointer py-2.5 font-medium text-sm`}>Color Coated</a>
                        </div>
                    </div>
                    <div className='w-full flex flex-col'>
                        <span className='font-semibold text-lg mb-5'>Contact us</span>
                        <div className={`${classes.address} flex flex-col items-start gap-4 font-normal text-xs`} >
                            <div>
                                <div className={`${classes.footerText} font-medium mb-1 text-xs`}>  Delhi Warehouse </div>
                                Plot no . 56/18/1, Ground Floor, Swarn Park, Mundka, Delhi-110041
                            </div>
                            <div>
                                <div className={`${classes.footerText} font-medium mb-1 text-xs`}> Mumbai Warehouse </div>
                                J-18, MIDC Taloja Industrial Area, Dist. Raigad, Maharashtra -410208
                            </div>
                            <div className="text-xs">
                                A-88, MIDC Taloja Industrial Area, Dist. Raigad, Maharashtra -410208
                            </div>
                            <div className='flex gap-2'>
                                <img src={callLogo} alt="Call Logo" /> +91 9654555510
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.borderTop} flex flex-col gap-y-5 pb-16 pt-5 justify-between`}>
                        <div className='flex justify-between my-auto text-sm font-medium'>
                            <Link to={CUSTOMER_ROUTES.TERMS_AND_CONDITIONS} className={`${classes.text} cursor-pointer px-1 py-2.5`}>Terms & Conditions</Link>
                            <Link to={CUSTOMER_ROUTES.PRIVACY_POLICY} className={`${classes.text} cursor-pointer px-1 py-2.5`}>Privacy Policy</Link>
                        </div>
                        <div className='flex justify-between mb-16 pb-16'>
                            <img src={footeravighanaLogo} className={classes.footerLogo} alt="Avighna Logo" />
                            <div className={`${classes.copyright} my-auto text-sm`}>
                                Copyright © {new Date().getFullYear()} Avighna Steel
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default Footer;
